.hero_subtitle {
   color: white;

   .hero_hashtag {
      color: #ab9d81;
   }

   .hero_hashtag:hover {
      text-decoration: underline;
   }
}