.hero_subtitle {
  color: white;
}
.hero_subtitle .hero_hashtag {
  color: #ab9d81;
}
.hero_subtitle .hero_hashtag:hover {
  text-decoration: underline;
}

:root {
  --primary: #cd5360;
  --primary-rgb: 205, 83, 96;
  --secondary: #30415d;
  --secondary-rgb: 48, 65, 93;
  --success: #57d685;
  --success-rgb: 87, 214, 133;
  --warning: #ffae00;
  --warning-rgb: 255, 174, 0;
  --alert: #ec5840;
  --alert-rgb: 236, 88, 64;
  --proposals: #238ff7;
  --actions: #57d685;
  --debates: #fa6c96;
  --meetings: #fabc6c;
  --twitter: #55acee;
  --facebook: #3b5998;
  --google: #dd4b39;
}

.mini-footer {
  background-color: #fff !important;
  color: #494949;
}

.decidim-logo {
  display: inline-block;
  padding-left: 1em;
  max-width: 35%;
}

.oh-logo {
  display: inline-block;
  padding-left: 1em;
  max-width: 35%;
}

.main-footer {
  background-color: #212c3f;
  color: #fff;
}

.mini-footer a {
  color: #30415d;
}

.eu-footer {
  background-color: #30415d;
  color: #fff;
  padding: 2em;
  font-size: 0.9em;
}
.eu-footer .flag-container {
  margin-left: 1em;
}
.eu-footer .flag-container img {
  max-width: none;
}
.eu-footer .disclaimer {
  float: left;
  width: 90%;
}
.eu-footer .disclaimer .column {
  margin-top: 1em;
}
@media screen and (min-width: 40em) {
  .eu-footer {
    display: flex;
  }
  .eu-footer .flag-container {
    margin: 0 1em;
    text-align: center;
  }
  .eu-footer .disclaimer .column {
    margin-top: 0;
  }
}
.eu-footer .flag {
  border: 3px white solid;
  height: 80px;
  margin: 10px 0 0;
  display: inline-block;
}