// Vars

// OpenHeritage StyleGuide colors
$primary-blue: #243146;
$primary-pink: #cd5360;
$primary-pink-rgb: 205,83,96;
$primary-gold: #ab9d81;
$secondary-blue: #30415d;
$secondary-blue-rgb: 48,65,93;
$secondary-grey: #494949;

// Decidim main vars
$primary: $primary-pink;
$primary-rgb: $primary-pink-rgb;
$secondary: $secondary-blue;
$secondary-rgb: $secondary-blue-rgb;
$success: #57d685 !default;
$success-rgb: 87,214,133;
$alert: #ec5840 !default;
$alert-rgb: 236,88,64;
$warning: #ffae00 !default;
$warning-rgb: 255,174,0;
$proposals: #238ff7 !default;
$actions: #57d685 !default;
$debates: #fa6c96 !default;
$meetings: #fabc6c !default;

$twitter: #55acee !default;
$facebook: #3b5998 !default;
$google: #dd4b39 !default;

$body-font-color: $secondary-grey;
$main-footer-bg: #212c3f;

// Custom vars
$title-bar-bg: #fff;
$link-color: $secondary-blue;
$navbar-fg: $secondary-grey;
$navbar-fg-hover: #333;
$navbar-bg: #f1f1f1;

// Define custom properties as css color variables

:root{
  --primary: #{$primary};
  --primary-rgb: #{$primary-rgb};
  --secondary: #{$secondary};
  --secondary-rgb: #{$secondary-rgb};
  --success: #{$success};
  --success-rgb: #{$success-rgb};
  --warning: #{$warning};
  --warning-rgb: #{$warning-rgb};
  --alert: #{$alert};
  --alert-rgb: #{$alert-rgb};
  --proposals: #{$proposals};
  --actions: #{$actions};
  --debates: #{$debates};
  --meetings: #{$meetings};
  --twitter: #{$twitter};
  --facebook: #{$facebook};
  --google: #{$google};
}
